@import "../../../variables.scss";

.builder-settings-container {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    z-index: 101;
    background-color: $primary-color;
    transition: width 0.8s;
    color: white;

    &.hide {
        display: none;
    }

    .settings-content {
        width: 300px;
        height: 100%;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            border-radius: 8px;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        }
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: white;
            border-radius: 8px;
        }
    }

    .MuiAccordion-root.Mui-expanded {
        margin: 0px !important;
    }

    .MuiAccordionSummary-root.Mui-expanded {
        min-height: unset !important;
    }

    .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px !important;
    }
}
