@import "../../variables.scss";

.roles-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    .roles {
        border-right: solid 1px #e6ebff;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        padding: 10px;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 8px;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        }
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #70707050;
            border-radius: 8px;
        }

        .role {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: 3px 15px 3px 7px;
            background-color: white;
            margin: 5px 0;
            border-radius: 7px;
            color: $primary-color;
            text-transform: capitalize;
            cursor: pointer;
            overflow: visible;

            .role-name {
                flex: 1;
                overflow: hidden;
                text-align: center;
            }
            .change-hint {
                position: absolute;
                top: -5px;
                right: -5px;
            }
        }

        .selected {
            color: white;
            background-color: $secondary-color;

            .remove {
                color: white;
            }
        }
    }
    .role-details {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        overflow-y: hidden;
        overflow-x: auto;
        padding: 10px;

        &::-webkit-scrollbar {
            height: 4px;
            border-radius: 8px;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        }
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #70707050;
            border-radius: 8px;
        }

        .role-name-container {
            min-width: 400px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .save-role {
                margin: 0 15px;
            }
        }

        .permissions {
            min-width: 400px;
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow: hidden;
            min-width: 400px;

            .permissions-title {
                color: $secondary-color;
                font-family: "Inter", sans-serif;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 0.7em;
                display: flex;
                padding: 5px;
                justify-content: space-between;
                div {
                    display: flex;
                    align-items: center;
                }
            }
            .permissions-body {
                flex: 1;
                display: flex;
                flex-direction: column;
                padding: 0 20px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 4px;
                    border-radius: 8px;
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                }
                &::-webkit-scrollbar-track {
                    background-color: transparent;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #70707050;
                    border-radius: 8px;
                }

                .permission-element {
                    display: flex;
                    flex-direction: row;
					align-items: center;
					column-gap: 5px;
                    padding: 5px 0;
                    border-bottom: 1px solid $tertiary-color-30;

                    .permission-element-left {
                        .permission-title {
                            text-transform: uppercase;
                            font-size: 1em;
                            font-weight: 500;
                            color: $primary-color;
                        }
                        .permission-desc {
                            font-size: 0.7em;
                            color: $tertiary-color;
                        }
                    }

                    .resource-actions {
                        display: flex;
                        flex-direction: row;
                        flex: 1;
                        flex-wrap: wrap;
                        align-items: center;
                        align-content: center;
                        justify-content: flex-end;
                    }

                    .permission-active {
                    }
                }
            }
        }
    }
}
