$primary-color: #34375a;
$secondary-color: #3d59d6;
$tertiary-color: #66718c;
$tertiary-color-10: #66718c10;
$tertiary-color-30: #66718c30;
$quaternary-color: #8089e1;
$primary-background-color: #f9fafe;
$nodes-color: #8089e1;
$headerHeight: 70px;
$footerHeight: 35px;
