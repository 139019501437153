@import "../../../../../variables.scss";

.sleep-node-container {
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-bottom-color: $nodes-color;
    position: relative;
    top: -50px;
    &.selected {
        border-bottom-color: $primary-color;
        &::after {
            border-top-color: $primary-color;
        }
    }
    
    & > .invalid-marker {
        position: absolute;
        width: 10px;
        height: 10px;
        left: -4px;
        top: -3px;
        background-color: red;
        border-radius: 5px;
    }

    & > div.sleep-content {
        position: absolute;
        z-index: 99;
        width: 50px;
        height: 50px;
        top: 25px;
        left: -25px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: white;
    }
    &::after {
        content: "";
        position: absolute;
        left: -50px;
        top: 49px;
        width: 0;
        height: 0;
        border: 50px solid transparent;
        border-top-color: $nodes-color;
    }
}
