@import "../../variables.scss";

.wokflows-container {
    position: relative;
    width: 100%;
    height: 100%;
    .error {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        font-weight: bold;
        color: red;
    }
}

.fullscreen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 2;
}
