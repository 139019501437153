.form-content-container {
    flex: 1;
    background-color: #fafafa;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        border-radius: 8px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #bbbbbb;
        border-radius: 8px;
    }
}
