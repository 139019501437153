@import "../../../../../variables.scss";

.end-node-container {
    width: 100px;
    height: 100px;
    border: solid 10px $nodes-color;
    border-radius: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    .end-node-inner {
        width: 70px;
        height: 70px;
        border: solid 5px $nodes-color;
        border-radius: 50px;
        box-sizing: border-box;
    }

    &.selected {
        border-color: $primary-color;
        .end-node-inner {
            border-color: $primary-color;
        }
    }
}
