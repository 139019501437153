@import "../../../../../variables.scss";

.dynamic-picker-container {
    *::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    *::-webkit-scrollbar-track {
    }
    *::-webkit-scrollbar-thumb {
        background-color: $secondary-color;
        border-radius: 8px;
    }
}
