body {
    font-family: "Metropolis" !important;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

*:not(.condition-builder-dialog .dialog-content *) {
    user-select: none;
    -webkit-user-drag: none;
    font-family: "Metropolis" !important;
}
