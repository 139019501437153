@import "../../variables.scss";

.loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    box-sizing: border-box;
    & > .loader {
        box-sizing: border-box;
    }
}
