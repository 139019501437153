@import "../../variables.scss";

.login-container {
	width: 100vw;
	height: 100vh;
	display: flex;
	& > * {
		flex: 1;
		height: 100%;
	}
	& > .left {
		flex-direction: column;
		.logo-box {
			box-sizing: border-box !important;
			width: 300px;
			height: 120px;
			position: absolute;
			top: 50px;
			left: 50px;
			background-image: url(../../assets/images/logo.png);
			background-size: contain;
			background-position: left;
			background-repeat: no-repeat;
		}
		.image-box {
			flex: 1;
			background-image: url(../../assets/images/login_image.png);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}
	}
	& > .right {
		background-color: white;
		& > .form-container {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			justify-content: center;
			& > * {
				margin-top: 10px;
				margin-bottom: 10px;
			}
			.mobile-logo-box {
				height: 120px;
				background-image: url(../../assets/images/logo.png);
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
			}
			header {
				& > h1 {
					font-size: 48px;
					font-weight: bold;
					color: $primary-color;
					text-transform: capitalize;
				}
			}
			form {
				display: flex;
				flex-direction: column;
				align-items: stretch;
				& > * {
					margin-top: 10px;
					margin-bottom: 10px;
				}
				& label {
					text-transform: capitalize;
				}
				.actions {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					a {
						color: $primary-color;
						font-size: 13;
						text-decoration: underline;
						cursor: pointer;
					}
					button {
						background-color: $primary-color;
						border-radius: 6px;
						border: none;
						outline: none;
						width: 40px;
						height: 40px;
						color: white;
						padding: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
					}
				}
			}
		}
		.language {
			position: absolute;
			bottom: 10px;
			right: 10px;
			color: $primary-color;
			font-weight: bold;
			font-size: 16px;
			& > button {
				color: $secondary-color;
				border: none;
				outline: none;
				background-color: transparent;
				font-weight: 500;
				font-size: 16px;
				text-decoration: underline;
				cursor: pointer;
			}
		}
	}
}
