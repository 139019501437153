@import "../../../../variables.scss";

.sidenav-container {
    & > .menu-container {
        .title {
            color: $primary-color;
            font-weight: bold !important;
        }
        .item-text {
            color: $tertiary-color;
        }
        a {
            text-decoration: none !important;
            color: unset !important;
        }
        .MuiListItemButton-root .MuiTouchRipple-child {
            background-color: $secondary-color !important;
        }
        .disabled-link {
            * {
                cursor: not-allowed;
            }
        }
    }
}
