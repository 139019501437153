.tasks-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .pending-tasks-container {
        width: 100%;
        display: flex;

        .pending-tasks {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            overflow-x: auto;
            gap: 16px;
            padding-bottom: 16px;
        }
    }

    .tasks-history-container {
    }

    .tasks-insights-container {
    }
}
