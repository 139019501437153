@import "../../../../../../variables.scss";

.file-preview-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding: 10vmin;
    background-color: rgba($color: #000000, $alpha: 0.9);
    z-index: 999999999999999;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > .close-button {
        position: absolute;
        top: 1vmax;
        right: 1vmax;
    }

    & > .file-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        & > .file-name-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            box-sizing: border-box;
            padding: 20px;
            .name {
                padding: 0;
                color: white;
                font-weight: bold;
                font-size: 20px;
                text-align: center;
            }
        }

        & > .file-viewer {
            width: 100%;
            height: 100%;
            position: relative;

            .custom-render-container {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;
            }
        }
    }

    & > .previous-button {
        position: absolute;
        left: 20px;
        font-size: 80px;
    }

    & > .next-button {
        position: absolute;
        right: 20px;
        font-size: 80px;
    }

    & > .disabled {
        display: none;
    }
}
