@import "../../../../../variables.scss";

.condition-node-container {
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-bottom-color: $nodes-color;
    position: relative;
    top: -50px;
    &.selected {
        border-bottom-color: $primary-color;
        &::after {
            border-top-color: $primary-color;
        }
    }
    & > div.condition-content {
        position: absolute;
        z-index: 99;
        width: 50px;
        height: 50px;
        top: 25px;
        left: -25px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        color: white;
    }
    &::after {
        content: "";
        position: absolute;
        left: -50px;
        top: 49px;
        width: 0;
        height: 0;
        border: 50px solid transparent;
        border-top-color: $nodes-color;
    }
}

.condition-top-handle {
    &::before {
        color: $nodes-color;
        font-size: 30px;
        font-weight: bold;
        content: "+";
        position: relative;
        top: -40px;
        left: -6px;
    }
}

.condition-bottom-handle {
    &::after {
        color: $nodes-color;
        font-size: 30px;
        font-weight: bold;
        content: "_";
        position: relative;
        top: -20px;
        left: -6px;
    }
}
