@import "../../../../../variables.scss";

.files-input-container {
    .dropzone-content {
        height: 100px;
        width: 100%;
        display: flex;
        flex-direction: row;
    }
    *::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        border-radius: 8px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
    *::-webkit-scrollbar-track {
        background-color: white;
    }
    *::-webkit-scrollbar-thumb {
        background-color: $primary-color;
        border-radius: 8px;
    }
}
