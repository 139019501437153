@import "../../../../../../variables.scss";

.md-input-modal-container {
    *::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        border-radius: 8px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
    *::-webkit-scrollbar-track {
        background-color: white;
    }
    *::-webkit-scrollbar-thumb {
        background-color: $primary-color;
        border-radius: 8px;
    }

    .md-input-rte-container {
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        .md-input-rte {
            overflow-x: visible;
            display: flex;
            flex-direction: column;
            z-index: 1;

            .quill {
                .ql-editor {
                }
            }
        }
    }
}
