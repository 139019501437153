@import "../../../variables.scss";

.builder-toolbar {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;

    .tools {
        height: 70px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow-x: auto;
        overflow-y: hidden;
        box-sizing: border-box;

        * {
            -webkit-user-drag: unset !important;
            user-select: unset !important;
        }

        .tool-icon {
            margin-left: 20px;
            margin-right: 20px;
            padding: 0;
            min-width: unset;
            width: 35px;
            height: 35px;
            border-radius: 50px;
            background-color: transparent;

            & > .icon {
                -webkit-user-drag: element !important;
            }
        }

        &::-webkit-scrollbar {
            height: 7px;
            border-radius: 8px;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        }
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #70707050;
            border-radius: 8px;
        }
    }
    .header {
        height: 50px;
        width: 100%;
        box-sizing: border-box;
        padding: 0 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: $nodes-color;
        .title {
            padding: 0;
            color: white;
            font-weight: bold;
            font-size: 20px;
        }
        .actions {
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            gap: 20px;
        }
    }
}
