.data-container {
    *::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        border-radius: 8px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
    *::-webkit-scrollbar-track {
        background-color: transparent;
    }
    *::-webkit-scrollbar-thumb {
        background-color: #70707050;
        border-radius: 8px;
    }

    .MuiDataGrid-cell {
        *::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            border-radius: 8px;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        }
        *::-webkit-scrollbar-track {
            background-color: transparent;
        }
        *::-webkit-scrollbar-thumb {
            background-color: #70707050;
            border-radius: 8px;
        }
    }
}
