@import "../../../../variables.scss";
.site-details-container {
    display: flex;
    flex-direction: column;
    height: 600px;
    width: 700px;
    border-radius: 7px;
    background-color: white;
    overflow: hidden;
    & > .header {
        height: 70px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 4px 6px rgba($color: $quaternary-color, $alpha: 0.1);
        padding-left: 27px;
        padding-right: 8px;
    }
    & > .body {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .tabs-content {
            overflow: auto;
            flex: 1;
            display: flex;
            flex-direction: column;

            &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }
            &::-webkit-scrollbar-track {
                background-color: #f3f3f3;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $quaternary-color;
                border-radius: 8px;
            }
        }

        .info {
            .item-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                * {
                    max-width: 100%;
                    word-wrap: break-word;
                }
            }
        }
    }
}
