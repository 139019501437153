@import "../../variables.scss";

.image-input-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    .image {
        width: 120px;
        height: 120px;
        background-color: $primary-color;
        border-radius: 60px;
        box-sizing: border-box;
        border: solid 4px white;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: stretch;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .icon-button {
            width: 100%;
            background-color: #00000055;
            display: flex;
            justify-content: center;
            align-items: center;
            * {
                color: white;
            }
        }
    }

    .label {
        color: $primary-color;
        font-size: 25px;
    }
}
