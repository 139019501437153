.image-preview-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding: 10vmin;
    background-color: rgba($color: #000000, $alpha: 0.9);
    z-index: 999999999999999;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    & > .close-button {
        position: absolute;
        top: 1vmax;
        right: 1vmax;
    }

    & > .image-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;

        & > .image-name-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            .name {
                padding: 0;
                color: white;
                font-weight: bold;
                font-size: 20px;
                text-align: center;
            }
        }
        & > img {
            width: 100%;
            height: calc(100% - 60px);
            object-fit: contain;
        }

        & > .visibility-button {
            position: absolute;
            bottom: 10px;
            font-size: 60px;
        }
    }

    & > .previous-button {
        position: absolute;
        left: 20px;
        font-size: 80px;
    }

    & > .next-button {
        position: absolute;
        right: 20px;
        font-size: 80px;
    }

    & > .disabled {
        display: none;
    }
}
