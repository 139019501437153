.sidenav-container {
	background-color: white;
	height: 100%;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 7px;
		border-radius: 8px;
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	}
	&::-webkit-scrollbar-track {
		background-color: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #70707050;
		border-radius: 8px;
	}
}
