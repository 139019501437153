@import "../../variables.scss";

.add-modal-container {
    .header {
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 4px 6px rgba($color: $quaternary-color, $alpha: 0.1);
        padding-left: 27px;
        padding-right: 8px;
    }
    .modal-form {
        position: relative;
        max-height: 500px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        &::-webkit-scrollbar-track {
            background-color: #f3f3f3;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $secondary-color;
            border-radius: 8px;
        }
    }
}
