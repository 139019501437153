.field-container {
    .field {
        &:not(.dragging) {
            transform: unset !important;
        }
        &.dragging {
            background-color: #ebebeb;
        }
        p {
            word-wrap: break-word;
        }
        &:hover:not(.dragging):not(.disabled) {
            background-color: #f6f6f6 !important;
        }
        &.disabled {
            cursor: not-allowed;
        }
    }
}
