.form-builder-dialog {
    .dialog-content {
        background-color: white;
        height: 90vh;
        width: 90vw;
        display: flex;
        flex-direction: row;
        position: relative;
        box-sizing: border-box;
    }
}
