@import "../../../../../variables.scss";

.mention-container {
    & > .mentions-input-box {
        display: flex;
        flex-direction: row;
        position: relative;
        border-radius: 4px;

        & > fieldset {
            text-align: left;
            position: absolute;
            bottom: 0;
            right: 0;
            top: -5px;
            left: 0;
            margin: 0;
            padding: 0 8px;
            pointer-events: none;
            border-radius: inherit;
            border-style: solid;
            border-width: 1px;
            overflow: hidden;
            min-width: 0%;
            border-color: rgba(0, 0, 0, 0.23);

            font-weight: 400;
            line-height: 1.4375em;
            letter-spacing: 0.00938em;
            color: rgba(0, 0, 0, 0.87);

            &.fieldset-error {
                border-color: #d32f2f !important;
            }

            & > legend.legend-not-shrinked {
                float: unset;
                overflow: hidden;
                display: block;
                width: auto;
                padding: 0;
                height: 11px;
                font-size: 0.75em;
                visibility: hidden;
                max-width: 0.01px;
                -webkit-transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1)
                    0ms;
                transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
                white-space: nowrap;
            }

            & > legend.legend-shrinked {
                float: unset;
                overflow: hidden;
                display: block;
                width: auto;
                padding: 0;
                height: 11px;
                font-size: 0.75em;
                visibility: hidden;
                max-width: 100%;
                -webkit-transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1)
                    0ms;
                transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
                white-space: nowrap;

                & > span {
                    padding-left: 5px;
                    padding-right: 5px;
                    display: inline-block;
                    opacity: 0;
                    visibility: visible;
                }
            }
        }

        &:hover {
            & > fieldset {
                border-color: rgba(0, 0, 0, 0.87);
            }
        }

        .mention-text-container {
            min-height: 40px;
            flex: 1;
            border-radius: 5px !important;
            border: none !important;
            outline: none !important;
            padding: 5px;
            color: white !important;

            span.selected-mention {
                background-color: white !important;
                padding: 4px;
                color: $primary-color !important;
                border-radius: 4px;
            }

            &[placeholder]:empty::before {
                content: attr(placeholder);
                color: #777777;
            }

            &[placeholder]:empty:focus::before {
                content: "";
            }
        }

        &:has(.mention-text-container:focus) {
            & > fieldset {
                border-color: #ffffff;
                border-width: 2px;
            }
        }
    }
}

.tribute-container {
    z-index: 999999999999999999;
    background-color: white;
    box-shadow: 1px 1px 5px rgba(#000000, 0.5);
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            padding: 5px;
            cursor: pointer;
            &.highlight {
                background-color: #f0f0f0;
            }
        }
    }
}
