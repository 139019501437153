@import "../../../../../../../variables.scss";

.form-field-container {
    padding: 10px 10px;
    &.selected {
        //background-color: rgba($color: $secondary-color, $alpha: 0.1);
        .field {
            box-shadow: 0px 0px 6px 4px
                rgba($color: $secondary-color, $alpha: 0.2);
            //background-color: rgba($color: $secondary-color, $alpha: 0.1);
        }
    }
    .field {
        padding: 16px;
        box-shadow: 0px 0px 4px rgba($color: $secondary-color, $alpha: 0.2);
        background-color: white;
        //border: solid 1px #aaaaaa;
        // border-radius: 10px;
        &.dragging {
            background-color: white;
            border-color: $secondary-color;
        }
    }
}
