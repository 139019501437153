@import "../../variables.scss";

.layout-container {
    background-color: white;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    & > .center {
        display: flex;
        height: calc(100vh - $headerHeight - $footerHeight);
        overflow: hidden;
        .content {
            background-color: $primary-background-color;
            flex: 1;
            position: relative;
            overflow: hidden;
            border-top-left-radius: 28px;
            border-bottom-left-radius: 28px;
        }
    }
    & > .bottom {
        height: $footerHeight;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        padding: 0 10px;

        .copyrights {
            color: $secondary-color;
            font-size: 16px;
        }

        .language {
            color: $primary-color;
            font-weight: bold;
            font-size: 16px;
            & > button {
                color: $secondary-color;
                border: none;
                outline: none;
                background-color: transparent;
                font-weight: 500;
                font-size: 16px;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}
