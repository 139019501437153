@import "../../../../variables.scss";

@keyframes hide {
    from {
        display: flex;
    }
    to {
        display: none;
    }
}

.fill-report-form-container {
    .pick-button {
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        background-color: transparent;
        border: solid 1px rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        text-align: start;
        &:hover {
            border: solid 1px rgba(0, 0, 0, 0.87);
        }
        &:active {
            border: solid 2px white;
        }
    }
}
