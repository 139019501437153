@import "../../../variables.scss";
.checkin-details-container {
    display: flex;
    flex-direction: column;
    height: 600px;
    width: 700px;
    border-radius: 7px;
    background-color: white;
    overflow: hidden;
    & > .header {
        height: 70px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 4px 6px rgba($color: $quaternary-color, $alpha: 0.1);
        padding-left: 27px;
        padding-right: 8px;
    }
    & > .body {
        flex: 1;
        overflow-x: hidden;
        overflow-y: auto;
        margin: 10px 20px 10px 20px;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        &::-webkit-scrollbar-track {
            background-color: #f3f3f3;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $quaternary-color;
            border-radius: 8px;
        }

        .image-container {
            display: flex;
            position: relative;
            width: fit-content;
            height: fit-content;

            .location-container {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;

                &:hover {
                    visibility: visible !important;
                    opacity: 1;
                    background-color: rgba($color: #000000, $alpha: 0.7);
                }
            }
        }
    }
}
