.md-input-container {
    .open-button {
        width: 100%;
        background-color: transparent;
        border: solid 1px rgba(0, 0, 0, 0.23);
        color: rgba(255, 255, 255, 0.867);
        border-radius: 4px;
        padding: 0px 14px;
        text-align: start;
        &:hover {
            border: solid 1px rgba(0, 0, 0, 0.87);
        }
        &:active {
            border: solid 2px white;
        }
    }
}
