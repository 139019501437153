@import "../../variables.scss";

.location-picker-container {
    .pick-button {
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        background-color: transparent;
        border: solid 1px rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        text-align: start;
        &:hover {
            border: solid 1px rgba(0, 0, 0, 0.87);
        }
        &:active {
            border: solid 2px white;
        }
    }
}

.gm-fullscreen-control {
    display: none;
}

.gm-svpc {
    display: none;
}

.gmnoprint {
    right: 0 !important;
    left: unset !important;
}

.location-picker-dialog {
    .map-container {
        height: 80vh;
        width: 80vw;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border: solid 4px $secondary-color;
        overflow: auto;
        box-sizing: border-box;

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            border-radius: 8px;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        }
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #70707050;
            border-radius: 8px;
        }
    }
}
