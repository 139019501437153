.cards-container {
    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
        border-radius: 8px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar-track {
        background-color: white;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #8a9be6;
        border-radius: 8px;
    }
}

.filters-container {
    &::-webkit-scrollbar {
        width: 10px;
        height: 3px;
        border-radius: 8px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar-track {
        background-color: white;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #8a9be6;
        border-radius: 8px;
        border-left: 3.5px solid white;
        border-right: 3.5px solid white;
    }
}
