@import "../../../../../variables.scss";

.comments-container {
    display: flex;
    flex-direction: column;
    position: relative;

    .comment-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin: 10px 0;
        gap: 10px;

        .comment {
            background-color: #f0f2f5;
            padding: 8px;
            border-radius: 10px;
            flex: 1;
            display: flex;
            flex-direction: column;
            margin-bottom: 35px;
            position: relative;

            &.selected {
                border: solid 2px $secondary-color;
            }

            & > .reply-button {
                position: absolute;
                left: 10px;
                bottom: -21px;
                color: #65676b;
                text-decoration: none;
                font-size: 13px;

                &:hover {
                    text-decoration: underline solid 1px;
                }
            }

            .header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
            }

            .text {
                margin-top: 5px;
            }

            .reply {
                margin: 5px;
                opacity: 0.8;
                border-left: solid 5px $secondary-color;
                background-color: #e0e2e5;
                padding: 2px;
                border-radius: 5px;

                .comment {
                    background-color: transparent;
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }

    .tag-suggestions-container {
        background-color: #f0f0f0;
        border-radius: 6px;
        overflow: hidden;
    }

    .write-comment-box {
        background-color: #f0f2f5;
        padding: 10px;
        border-radius: 13px;

        .reply {
            flex: 1;
            margin: 5px;
            opacity: 0.8;
            border-left: solid 5px $secondary-color;
            background-color: #e0e2e5;
            padding: 2px;
            border-radius: 5px;

            .comment {
                background-color: transparent;
                padding: 0;
                margin: 0;
            }
        }

        .write-comment-container {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin: 10px 0;
            gap: 10px;

            .my-comment-container {
                flex: 1;
                min-height: 50px !important;
                border-radius: 5px !important;
                background-color: #ffffff !important;
                border: none !important;
                outline: none !important;
                padding: 5px;

                span.selected-mention {
                    background-color: $quaternary-color !important;
                    padding: 4px;
                    color: white;
                    border-radius: 4px;
                }

                &[placeholder]:empty::before {
                    content: attr(placeholder);
                    color: #777777;
                }

                &[placeholder]:empty:focus::before {
                    content: "";
                }
            }
        }
    }
}

.tribute-container {
    z-index: 999999999999999999;
    background-color: white;
    box-shadow: 1px 1px 5px rgba(#000000, 0.5);
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            padding: 5px;
            cursor: pointer;
            &.highlight {
                background-color: #f0f0f0;
            }
        }
    }
}
