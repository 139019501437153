@import "../../../../../variables.scss";

.edit-site-node-container {
    width: 0;
    height: 0;
    border-bottom: 50px solid $nodes-color;
    border-right: 100px solid transparent;
    position: relative;
    top: -25px;
    & > .invalid-marker {
        position: absolute;
        width: 10px;
        height: 10px;
        left: -4px;
        top: -3px;
        background-color: red;
        border-radius: 5px;
    }
    &.selected {
        border-bottom-color: $primary-color;
        &::after {
            border-top-color: $primary-color;
        }
    }
    & > div.edit-site-content {
        position: absolute;
        z-index: 99;
        width: 50px;
        height: 50px;
        top: 25px;
        left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        color: white;
    }
    &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: 49px;
        border-top: 50px solid $nodes-color;
        border-right: 100px solid transparent;
    }
}
