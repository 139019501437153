@import "../../../../variables.scss";
.fill-form-container {
    display: flex;
    flex-direction: column;
    height: 600px;
    max-width: 700px;
    border-radius: 7px;
    background-color: white;
    overflow: hidden;

    & > .header {
        min-height: 70px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 4px 6px rgba($color: $quaternary-color, $alpha: 0.1);
        padding-left: 27px;
        padding-right: 8px;
    }

    & > .body {
        flex: 1;
        overflow-y: auto;
        margin: 10px 20px 10px 20px;
        display: flex;

        form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
        }

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        &::-webkit-scrollbar-track {
            background-color: #f3f3f3;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $secondary-color;
            border-radius: 8px;
        }
    }
}
