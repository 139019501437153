.react-flow__attribution {
    & > a {
        display: none;
    }
}

.react-flow__handle {
    z-index: 100;
    width: 8px !important;
    height: 8px !important;
}

.flow-container {
    width: 100%;
    height: 100%;
}
