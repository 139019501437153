@import "../../../../../variables.scss";

.condition-builder-dialog {
    .dialog-content {
        background-color: white;
        height: 90vh;
        width: 90vw;
        display: flex;
        flex-direction: column;
        position: relative;
        box-sizing: border-box;
        padding: 10px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
        }
        &::-webkit-scrollbar-track {
            background-color: #f3f3f3;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $secondary-color;
            border-radius: 8px;
        }
    }
}
