@import "../../../variables.scss";

.header-container {
    background-color: white;
    height: $headerHeight;
    display: flex;
    flex-direction: row;
    align-items: center;
    & > header {
        height: 100%;
        background-color: white;
        box-shadow: none;
        & > .toolbar {
            padding: 0 10px;
            .logo {
                height: 50%;
                object-fit: contain;
                cursor: pointer;
            }
        }
    }
}

.header-notifications-modal {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    &::-webkit-scrollbar-track {
        background-color: #f3f3f3;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $secondary-color;
        border-radius: 8px;
    }
}
