.multi-email-container {
    & > .emails-input-box {
        display: flex;
        flex-direction: row;
        position: relative;
        border-radius: 4px;

        & > .react-multi-email {
            min-height: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
            align-content: center;
            background-color: transparent;
            color: rgba(255, 255, 255, 0.867);
            border-radius: 4px;
            padding: 0px 14px;
            text-align: start;
            padding: 8.5px 14px;
            cursor: text;
            border: none !important;

            &.focused {
                background-color: transparent;
                color: rgba(255, 255, 255, 0.867);
            }

            &:not(.focused):has(input:not(input:focus)[value=""], input:not(input:focus, [value])) {
                & > input {
                    height: 0 !important;
                    padding: 0px !important;
                }
            }

            & > span[data-placeholder="true"] {
                color: rgba(255, 255, 255, 0.867) !important;
                font-weight: 400 !important;
                font-size: 1rem !important;
                padding: 0 !important;
            }

            & > input {
                // padding: 0 !important;
                background-color: transparent !important;
                color: white !important;
                font-weight: 400 !important;
                font-size: 1rem !important;
            }
        }
        & > fieldset {
            text-align: left;
            position: absolute;
            bottom: 0;
            right: 0;
            top: -5px;
            left: 0;
            margin: 0;
            padding: 0 8px;
            pointer-events: none;
            border-radius: inherit;
            border-style: solid;
            border-width: 1px;
            overflow: hidden;
            min-width: 0%;
            border-color: rgba(0, 0, 0, 0.23);

            font-weight: 400;
            line-height: 1.4375em;
            letter-spacing: 0.00938em;
            color: rgba(0, 0, 0, 0.87);

            &.fieldset-error {
                border-color: #d32f2f !important;
            }

            & > legend.legend-not-shrinked {
                float: unset;
                overflow: hidden;
                display: block;
                width: auto;
                padding: 0;
                height: 11px;
                font-size: 0.75em;
                visibility: hidden;
                max-width: 0.01px;
                -webkit-transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1)
                    0ms;
                transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
                white-space: nowrap;
            }

            & > legend.legend-shrinked {
                float: unset;
                overflow: hidden;
                display: block;
                width: auto;
                padding: 0;
                height: 11px;
                font-size: 0.75em;
                visibility: hidden;
                max-width: 100%;
                -webkit-transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1)
                    0ms;
                transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
                white-space: nowrap;

                & > span {
                    padding-left: 5px;
                    padding-right: 5px;
                    display: inline-block;
                    opacity: 0;
                    visibility: visible;
                }
            }
        }

        &:hover {
            & > fieldset {
                border-color: rgba(0, 0, 0, 0.87);
            }
        }

        &:has(.react-multi-email input:focus) {
            & > fieldset {
                border-color: #ffffff;
                border-width: 2px;
            }
        }
    }
}
