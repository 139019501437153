.fields-propeties-container {
    width: 250px;
    padding: 16px;
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;

    &.large {
        width: 350px;
    }
    &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        border-radius: 8px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar-track {
        background-color: white;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #bbbbbb;
        border-radius: 8px;
    }
}
